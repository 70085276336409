import { format } from "date-fns";
import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { toast } from "react-toastify";
import { DeviceContext } from "../state/DeviceContext";

export default function ExportButton({
  className,
  scouts,
  tStart,
  tEnd,
  ...rest
}) {
  const { getMeasurementsCSV } = useContext(DeviceContext);
  return (
    <button
      className={className}
      onClick={() => {
        document.body.style.cursor = "wait";
        getMeasurementsCSV(scouts, {
          since: tStart.toISOString(),
          until: tEnd.toISOString(),
        })
          .then((resp) =>
            resp.text().then((data) => {
              if (data !== "") {
                let blob = new Blob([data], { type: "text/csv" });
                let a = document.createElement("a");
                a.download = `${format(
                  tEnd,
                  "yyyy-MM-dd"
                )}_soil-scout_export.csv`;
                a.href = URL.createObjectURL(blob);
                a.dataset.downloadurl = [
                  "data:text/csv;charset=utf-8",
                  a.download,
                  a.href,
                ].join(":");
                a.style.display = "none";
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                setTimeout(function () {
                  URL.revokeObjectURL(a.href);
                }, 1500);
              } else {
                toast.warning(
                  <FormattedMessage id="warning.nothing_to_export" />
                );
              }
            })
          )
          .finally(() => (document.body.style.cursor = "default"));
      }}
      {...rest}
    >
      <FormattedMessage id="action.export_csv" />
    </button>
  );
}
