import React, { useContext } from "react";
import { SiteContext } from "../state/SiteContext";
import { UserContext } from "../state/UserContext";
import Form from "./Form";
import {
  Input,
  LabeledWidget,
  LocationPicker,
  OfflineNotificationsEditor,
  Checkbox,
  Select,
} from "./FormWidgets";
import {FormattedMessage } from "react-intl";

const SiteForm = ({ site, onSave, creating }) => {
  const { currentUser, users } = useContext(UserContext);
  
  // Get users with access to site for admins and normal users.
  // Check site, if null we are making a new site and need all users with privilege.
  const usersWithSiteAccess =
    site ? 
    users.filter(user => user.sites.some(id => (site && id === site?.id)))
    .concat(site?.main_user ? [site.main_user] : [])
    .filter((user, index, array) => array.findIndex(u => u.id === user.id) === index)
    : users;

  return (
    <Form
      context={SiteContext}
      defaultValues={
        site
          ? {
              ...site,
              site_name: site.name,
              location: { latitude: site.latitude, longitude: site.longitude },
              emails: site.offline_notifications?.emails,
              enabled: site.offline_notifications?.enabled,
              triggers: site.offline_notifications?.triggers,
              offline_notifications: site?.offline_notifications,
              main_user: site?.main_user ? [site.main_user.id] : [],
              demo_site: site.demo_site,
              site_hidden: site.site_hidden
            }
          : { enabled: true }
      }
      transform={(formValues) => {
        let offline_notifications = site?.offline_notifications
          ? site.offline_notifications
          : null;

        // Populate the offline_notifications field if enabled is checked
        if (formValues.enabled) {
          offline_notifications = {
            triggers: formValues.triggers?.map(
              ({ device_type, trigger_time }) => ({
                device_type,
                trigger_time: trigger_time * 60,
              })
            ),
            emails: formValues.emails
              ? formValues.emails
                  .filter(
                    ({ email }, idx, arr) =>
                      // Filtering out empty strings
                      email !== "" &&
                      // and duplicates
                      arr.map(({ email }) => email).indexOf(email) === idx
                  )
                  // Extract the value from the key: value pair
                  .map((mail) => mail["email"])
              : null,
            enabled: formValues.enabled,
          };

          // Offline_notifications have been set earlier and we just want to deactivate them.
        } else if (offline_notifications !== null) {
          offline_notifications.enabled = false;
        }

        const name = formValues.site_name;
        
        const main_user = formValues.main_user?.value;

        delete formValues.emails;
        delete formValues.enabled;
        delete formValues.trigger_time;
        delete formValues.triggers;
        delete formValues.site_name;

        if (formValues.location) {
          const { image, time_zone, latitude, longitude } = formValues.location;
          delete formValues.location;

          return {
            ...site,
            ...formValues,
            name,
            offline_notifications,
            latitude,
            longitude,
            image,
            time_zone,
            main_user
          };
        } else {
          return {
            ...site,
            ...formValues,
            name,
            offline_notifications,
          };
        }
      }}
      onSave={onSave}
      creating={creating}
      validateOnSubmit={true}
      readOnly={currentUser.read_only}
    >
      <LabeledWidget
        name="site_name"
        labelId="form.label.site_name"
        widget={<Input />}
      />
      <LocationPicker name="location" withImage />
      <OfflineNotificationsEditor site={site} />

      <LabeledWidget
        controlled
        className="flex flex-row justify-end"
        name="main_user"
        labelId="form.label.main_user"
        widget={<Select />}
        options={currentUser.is_superuser 
          ? users.sort((a, b) => a.last_name.localeCompare(b.last_name))
          .map((user, idx) => (
            {value: user.id, label: user.last_name + " " + user.first_name   + " (" + user.username + ")"}))
          : usersWithSiteAccess.sort((a, b) => a.last_name.localeCompare(b.last_name))
          .map((user, idx) => (
            {value: user.id, label: user.last_name + " " + user.first_name   + " (" + user.username + ")"}))
        }
      />

      {currentUser.is_superuser && (
        <LabeledWidget
        className="flex flex-row justify-end"
        name="client_id"
        labelId="form.label.client_id"
        widget={
            <>{site?.client_id === undefined  || site?.client_id === null ? 
                <FormattedMessage id="site.not_set" /> 
                : site.client_id }
            </>
          }
      />
      )}
      

      {currentUser.is_superuser && (
        <LabeledWidget
          className="flex flex-row justify-end"
          name="paid_until"
          labelId="form.label.paid_until"
          widget={
              <>{site?.paid_until === undefined  || site?.paid_until === null ?  
                  <FormattedMessage id="site.not_set" />  
                  : new Date(site.paid_until).toLocaleDateString()}
              </>
          }
        />
      )}
      

      {currentUser.is_superuser && (
        <LabeledWidget
          className="flex flex-row justify-end"
          name="site_hidden"
          labelId="form.label.site_hidden"
          widget={<Checkbox />}
        />
      )}

      {currentUser.is_superuser && (
        <LabeledWidget
          className="flex flex-row justify-end"
          name="demo_site"
          labelId="form.label.demo_site"
          widget={<Checkbox />}
        />
      )}
      
    </Form>
  );
};

export default SiteForm;
