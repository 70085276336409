import classNames from "classnames";
import { loadModules, setDefaultOptions } from "esri-loader";
import React, {
  useMemo,
  cloneElement,
  forwardRef,
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { FiChevronDown, FiMapPin, FiMinus, FiPlus, FiX } from "react-icons/fi";
import { FormattedMessage, useIntl } from "react-intl";
import { default as ReactSelect } from "react-select";
import { DeviceContext } from "../state/DeviceContext";
import DeviceGroupContext from "../state/DeviceGroupContext";
import { SiteContext } from "../state/SiteContext";
import { UserContext } from "../state/UserContext";
import config from "../tailwindConfig";
import { AnglePicker } from "react-linear-gradient-picker";
import "react-linear-gradient-picker/dist/index.css";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { scoutsHaveDevice } from "../utility/device-helpers";

/* Base widgets */

export const PlainSelect = forwardRef(
  (
    {
      className,
      error,
      children,
      white,
      disabled,
      simple,
      pointer,
      invisibleIcon,
      iconVisibility,
      ...rest
    },
    ref
  ) => (
    <div className={classNames(className, "relative w-full")}>
      <select
        ref={ref}
        className={classNames(
          "appearance-none placeholder-gray-800 transition-all duration-100 rounded",
          "border-2 border-transparent my-1 leading-6 w-full outline-none min-w-0",
          simple
            ? "border-0 bg-transparent py-1"
            : "focus:border-scout-blue border-2 py-2 px-3",
          pointer && "cursor-pointer",
          { "border-scout-red": error },
          { "bg-white border-gray-20": white && !disabled && !simple },
          { "bg-scout-gray-input": !white && !disabled && !simple },
          { "hover:border-gray-400 ": !disabled && !simple },
          { "bg-gray-100": disabled && !simple }
        )}
        disabled={disabled}
        {...rest}
      >
        {children}
      </select>
      {!disabled && (
        <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
          <FiChevronDown
            className={classNames(
              "h-6 w-6",
              invisibleIcon && !iconVisibility ? "invisible" : "",
              {
                "text-gray-500": disabled,
                "text-gray-700": !disabled,
              }
            )}
          />
        </div>
      )}
    </div>
  )
);

export const Input = forwardRef(
  (
    { error, className, white, clearable, isDisabled, disabled, ...rest },
    ref
  ) => (
    <input
      ref={ref}
      onKeyPress={(event) => {
        event.key === "Enter" && event.preventDefault();
      }}
      className={classNames(
        className,
        "appearance-none placeholder-gray-500 transition-all duration-100 rounded",
        "border-2 border-transparent py-2 px-3 my-1 leading-6 w-full",
        "outline-none focus:border-scout-blue min-w-0",
        { "border-scout-red": error },
        { "bg-white border-gray-20": white && !disabled },
        { "bg-scout-gray-input": !white && !disabled },
        { "hover:border-gray-400": !disabled },
        { "bg-gray-100": disabled }
      )}
      aria-invalid={error}
      disabled={disabled}
      {...rest}
    />
  )
);

export const Select = ({ white, error, options, value, disabled, ...rest }) => {
  const intl = useIntl();

  return (
    <ReactSelect
      className="w-full"
      styles={{
        option: (base, state) => ({
          ...base,
          backgroundColor: state.isSelected
            ? config.theme.colors["scout-blue"].default
            : "white",
          "&:hover": {
            backgroundColor: !state.isSelected
              ? config.theme.colors.indigo["100"]
              : null,
          },
        }),
        control: (base, state) => {
          return {
            ...base,
            borderRadius: config.theme.borderRadius.default,
            boxShadow: "none",
            borderColor: error
              ? config.theme.colors["scout-red"].default
              : state.isFocused
              ? config.theme.colors["scout-blue"].default
              : "transparent",
            borderWidth: config.theme.borderWidth["2"],
            backgroundColor: state.isDisabled
              ? config.theme.colors["gray"]["100"]
              : white
              ? "white"
              : config.theme.colors["scout-gray"].input,
            "&:hover": {
              borderColor: state.isFocused
                ? config.theme.colors["scout-blue"].default
                : config.theme.colors.gray["400"],
            },
          };
        },
        indicatorSeparator: () => ({
          display: "none",
        }),
        dropdownIndicator: (base, state) => ({
          ...base,
          color: state.isDisabled
            ? "transparent"
            : config.theme.colors["scout-black"],
          "&:hover": {
            color: config.theme.colors["scout-black"],
          },
        }),
        multiValue: (base) => ({
          ...base,
          backgroundColor: "white",
          borderRadius: config.theme.borderRadius.sm,
          fontSize: config.theme.fontSize.base,
        }),
        multiValueRemove: (base, state) => ({
          ...base,
          display: state.isDisabled ? "none" : base.display,
          "&:hover": {
            color: "white",
            borderRadius: config.theme.borderRadius.sm,
            backgroundColor: config.theme.colors["scout-red"].light,
          },
        }),
      }}
      placeholder={intl.formatMessage({id: "dropdown_select.placeholder.select"})}
      options={options}
      isDisabled={disabled}
      {...(disabled ? { placeholder: "" } : {})}
      {...rest}
      value={
        Array.isArray(value)
          ? options.filter((opt) => value.includes(opt.value))
          : options.find((option) => option.value === value)
      }
    />
  );
};

export const ToggleSwitch = forwardRef(({ id, name, error, ...rest }, ref) => {
  const randomId = id ? id : `toggle-${new Date().getTime()}`;
  return (
    <div className="relative h-full w-16 inline-flex items-center">
      <input
        id={randomId}
        ref={ref}
        name={name}
        className="toggle-switch"
        type="checkbox"
        aria-invalid={error}
        {...rest}
      />
      <label htmlFor={randomId} />
    </div>
  );
});

export const Checkbox = forwardRef(({ error, className, ...rest }, ref) => (
  <input
    ref={ref}
    type="checkbox"
    className={
      "form-checkbox w-5 h-5 border-2 transition-colors duration-75 rounded-sm" +
      " text-scout-gray-dark focus:shadow-none focus:border-scout-blue" +
      " hover:border-scout-gray-dark" +
      " " +
      (className ? className : "")
    }
    {...rest}
  />
));

export const UseConsentCheckbox = (label) => {
  const [toggled, setToggled] = useState(false);
  const checkbox = useMemo(
    () => (
      <input
        key={`label ${Math.random()}`}
        type="checkbox"
        value={label}
        defaultChecked={toggled}
        onClick={() => setToggled((toggled) => !toggled)}
        className={
          "form-checkbox w-5 h-5 border-2 transition-colors duration-75 rounded-sm" +
          " text-scout-gray-dark focus:shadow-none focus:border-scout-blue" +
          " hover:border-scout-gray-dark" +
          " "
        }
      />
    ),
    [label, toggled]
  );
  return [toggled, checkbox];
};

export const DatePicker = forwardRef(({  error, className, ...rest }, ref) => (
  <input
    ref={ref}
    type="date"
    className={
      "bg-scout-gray-input" +
      " duration-100 rounded border-2 border-transparent" + 
      " h-10  focus:border-scout-blue hover:border-scout-gray-dark" +
      " " +
      (className ? className : "")
    }
    {...rest}
  />
));

export const FormSeparator = () => <hr className="col-span-2 -mx-10" />;

export const ValidationMessage = ({ messageId }) =>
  messageId ? (
    <p className="ml-4 mt-1 italic text-red-600">
      <FormattedMessage id={messageId} />
    </p>
  ) : null;

const Help = ({ className, messageId }) =>
  messageId ? (
    <p className={classNames(className, "ml-4 mt-1 text-scout-gray-dark")}>
      <FormattedMessage id={messageId} />
    </p>
  ) : null;

export const FormLabel = ({
  className = "col-start-1",
  htmlFor,
  messageId,
  disabled,
  required,
  hideLabel = false,
  labelClasses,
  labelAppend
}) =>
  messageId ? (
    <FormattedMessage id="form.validation.required">
      {(requiredMessage) => (
        <label
          title={required ? requiredMessage : null}
          htmlFor={htmlFor}
          className={classNames(
            className,
            labelClasses,
            `whitespace-no-wrap font-bold ${hideLabel ? "hidden" : ""}`
          )}
        >
          <FormattedMessage id={messageId} values={labelAppend}/>
          {required && !disabled && <span className="text-scout-red"> *</span>}
        </label>
      )}
    </FormattedMessage>
  ) : null;

const Wrapper = ({ className, children }) => (
  <div
    className={classNames(
      className,
      "col-start-1 col-span-2 sm:col-span-1 sm:col-start-2",
      { "flex flex-col": !className }
    )}
  >
    {children}
  </div>
);

export const LabeledWidget = ({
  name,
  labelId,
  className,
  helpId,
  widget,
  controlled = false,
  required,
  ref,
  immReg,
  errorAcessor,
  labelOnTop,
  hideLabel = false,
  visible = true,
  labelClasses,
  labelAppend,
  ...rest
}) => {
  const { register, errors } = useFormContext();
  const error = name
    .split(/[.[\]'"]/)
    .filter((p) => p)
    .reduce((a, b) => (a ? a[b] : undefined), errors);

  const elemProps = {
    ...widget.props,
    id: `input-${name}`,
    error: error !== undefined,
    name: name,
    ...rest,
  };

  const elem = controlled ? (
    <Controller
      name={name}
      render={({ onChange, value }) =>
        cloneElement(widget, {
          ...elemProps,
          onChange: (val) =>
            elemProps.onChange
              ? onChange(elemProps.onChange(val))
              : onChange(val),
          value,
        })
      }
    />
  ) : (
    cloneElement(widget, { ...elemProps, ref: register() })
  );

  return (
    <>
      {labelId && (
        <FormLabel
          htmlFor={`input-${name}`}
          messageId={labelId}
          disabled={rest.disabled}
          required={required}
          hideLabel={hideLabel}
          labelClasses={labelClasses}
          className={!visible ? "hidden" : labelOnTop ? "col-start-2" : null}
          labelAppend={labelAppend}
        />
      )}
      <Wrapper className={visible ? className : "hidden"}>
        {elem}
        {error ? (
          <ValidationMessage messageId={error?.message} />
        ) : helpId ? (
          <Help messageId={helpId} />
        ) : null}
      </Wrapper>
    </>
  );
};

export const Fieldset = ({
  legendId,
  helpId,
  inline,
  grow = true,
  children,
  disabled,
}) => (
  <>
    {legendId && (
      <legend className="hidden sm:inline-block col-start-1 whitespace-no-wrap font-bold my-auto">
        <FormattedMessage id={legendId} />
      </legend>
    )}

    <div
      className={classNames(
        "col-span-2 sm:col-span-1 flex w-full justify-end",
        { "flex-col": !inline },
        { "space-x-2": inline }
      )}
    >
      {[].concat(children).map((child, idx) => (
        <div
          key={idx}
          className={classNames(
            { "flex-1 my-1": grow },
            {
              "flex-col items-center justify-evenly": inline,
            }
          )}
        >
          {cloneElement(child, {
            ...child.props,
            disabled,
          })}
        </div>
      ))}
    </div>
    {helpId && <Help className="col-start-2" messageId={helpId} />}
  </>
);

/* Concrete widgets */

export const SoilTypeSelect = (props) => (
  <DeviceContext.Consumer>
    {({ soilTypes }) => (
      <LabeledWidget
        widget={
          <PlainSelect>
            {soilTypes.map((type) => (
              <FormattedMessage key={type} id={`soil_type.${type}`}>
                {(message) => <option value={type}>{message}</option>}
              </FormattedMessage>
            ))}
          </PlainSelect>
        }
        labelId={"form.label.soil_type"}
        {...props}
      />
    )}
  </DeviceContext.Consumer>
);

export const AntennaSelector = (props) => {
  const { watch, setValue } = useFormContext();
  const intl = useIntl();

  const type = watch("antenna_type");
  const angle = watch("antenna_orientation");

  // Takes angles in degrees and returns a Cardinal Direction (eg N, NE, E, SE...)
  const angleToCardinalDirection = (angle) => {
    if (angle >= 337.5 || angle < 22.5) return intl.formatMessage({id: "form.antenna_orientation.north"});
    else if (angle >= 22.5 && angle < 67.5) return intl.formatMessage({id: "form.antenna_orientation.ne"});
    else if (angle >= 67.5 && angle < 112.5) return intl.formatMessage({id: "form.antenna_orientation.e"});
    else if (angle >= 112.5 && angle < 157.5) return intl.formatMessage({id: "form.antenna_orientation.se"});
    else if (angle >= 157.5 && angle < 202.5) return intl.formatMessage({id: "form.antenna_orientation.s"});
    else if (angle >= 202.5 && angle < 247.5) return intl.formatMessage({id: "form.antenna_orientation.sw"});
    else if (angle >= 247.5 && angle < 292.5) return intl.formatMessage({id: "form.antenna_orientation.w"});
    else if (angle >= 292.5 && angle < 337.5) return intl.formatMessage({id: "form.antenna_orientation.nw"});
  };

  return (
    <DeviceContext.Consumer>
      {({ antennaTypes }) => (
        <>
          <FormLabel
            htmlFor="form.label.antenna_type"
            messageId="form.legend.antenna_type"
            disabled={false}
            required={false}
          />
          <LabeledWidget
            widget={
              <PlainSelect>
                {antennaTypes.map((antenna) => (
                  <option value={antenna.id}>{antenna.value}</option>
                ))}
              </PlainSelect>
            }
            labelId={"form.label.antenna_type"}
            hideLabel={true}
            name="antenna_type"
          />
          {type === "directive_yagi" && (
            <div className="flex justify-center col-span-2 mt-2">
              <div className="self-center mr-4 flex flex-col">
                <FormLabel
                  htmlFor="form.label.antenna_orientation"
                  messageId="form.label.antenna_orientation"
                  disabled={false}
                  required={false}
                />
                <span className="self-center">
                  {angleToCardinalDirection(angle)}
                </span>
              </div>
              <LabeledWidget
                widget={
                  <AnglePicker
                    size={80}
                    setAngle={(newAngle) => {
                      setValue("antenna_orientation", newAngle, {
                        shouldDirty: true,
                      });
                    }}
                    angle={angle}
                    snap={45}
                  />
                }
                hideLabel={true}
                name="antenna_orientation"
                controlled
              />
            </div>
          )}
        </>
      )}
    </DeviceContext.Consumer>
  );
};

export const GroupSelect = (props) => (
  <DeviceGroupContext.Consumer>
    {({ groups }) => (
      <LabeledWidget
        controlled
        labelId={"form.label.groups"}
        isMulti={true}
        widget={<Select />}
        options={
          groups
            ? groups
                .sort((a, b) => a.name.localeCompare(b.name))
                .map(({ id: value, name: label }) => ({
                  value,
                  label,
                }))
            : []
        }
        onChange={(arr) => (arr ? arr.map((entry) => entry?.value) : [])}
        {...props}
      />
    )}
  </DeviceGroupContext.Consumer>
);

export const SingleGroupSelect = (props) => {
  const [hidden, setHidden] = useState();
  const hideMessage = () => {
    setHidden("hidden")
    localStorage.setItem("hidden-alertInfo", "hidden");
  };
  const isMessageHidden = () => {
    if (hidden === "hidden") {
      return "hidden";
    } else if (localStorage.getItem("hidden-alertInfo") === "hidden") {
      return "hidden";
    }
    return "";
  }

  const hasGroups = (groups) => {
    if (groups.length === 0 && hidden !== "hidden") {
      localStorage.removeItem("hidden-alertInfo")
      setHidden("")
    }
  }

  useEffect(() => {}, [hidden]);


  return (
    <>
      <div className={`flex flex-row justify-start col-span-2 ${isMessageHidden()}`}>
        <p className="font-small text-gray-600">
          <FormattedMessage id="alert.only_groups" />
        </p>
        <Link className="font-small text-scout-blue ml-1 hover:underline" to={`/sites/${props.site_id}/scouts/edit/groups/`}> Create New Group.</Link>
        <div className="flex-grow justify-end align-end">
          <div onClick={hideMessage} className="relative float-right cursor-pointer"><FiX className="text-scout-red-light" size={20} /></div>
        </div>
      </div>
      <DeviceGroupContext.Consumer>
        {({ groups }) => (
          <LabeledWidget
            labelId={"form.label.group"}
            widget={
              <PlainSelect>
                {hasGroups(groups)}
                {groups
                  ? [<option key="placeholder" value=""></option>].concat(
                      groups
                        .sort((a, b) => a.name > b.name)
                        .map((group) => (
                          <option key={group.id} value={group.id}>
                            {group.name}
                          </option>
                        ))
                    )
                  : []}
              </PlainSelect>
            }
            {...props}
          />
        )}
      </DeviceGroupContext.Consumer>
    </>
  )
};

export const DeviceSelect = (props) => (
  <DeviceContext.Consumer>
    {({ scouts }) => (
      <LabeledWidget
        controlled
        isMulti
        labelId={"form.label.groups"}
        widget={<Select />}
        options={scouts
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(({ id: value, name: label }) => ({
            value,
            label,
          }))}
        onChange={(arr) => (arr ? arr.map((entry) => entry?.value) : [])}
        {...props}
      />
    )}
  </DeviceContext.Consumer>
);

export const BaseSelect = (props) => (
  <DeviceContext.Consumer>
    {({ bases }) => (
      <LabeledWidget
        controlled
        isMulti
        widget={<Select />}
        options={bases
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(({ id: value, name: label }) => ({
            value,
            label,
          }))}
        onChange={(arr) => (arr ? arr.map((entry) => entry?.value) : [])}
        {...props}
      />
    )}
  </DeviceContext.Consumer>
);

export const DeviceTypeSelect = (props) => {
  const deviceTypes = ["base", "echo", "hydra"];
  return (
    <LabeledWidget
      widget={
        <PlainSelect>
          {deviceTypes.map((type) => (
            <FormattedMessage key={type} id={`device.type.${type}`}>
              {(message) => <option value={type}>{message}</option>}
            </FormattedMessage>
          ))}
        </PlainSelect>
      }
      {...props}
    />
  );
};

const useToggle = (initialState) => {
  const [isToggled, setIsToggled] = useState(initialState);
  const toggle = React.useCallback(() => setIsToggled((state) => !state), [
    setIsToggled,
  ]);

  return [isToggled, toggle];
};

export const OfflineNotificationsEditor = ({ name, disabled, site }) => {
  const [isToggledEcho, setToggledEcho] = useState(site === undefined ? false : site?.offline_notifications?.triggers?.findIndex(e => e.device_type === "echo") >= 0);
  const [isToggledBase, setToggledBase] = useState(site === undefined ? false : site?.offline_notifications?.triggers?.findIndex(e => e.device_type === "base") >= 0);
  const [showInfo, setShowInfo]= useState()
  const [isToggled, toggle] = useToggle(
    site?.offline_notifications?.enabled === true
  );
  const shouldBeVisible = () => {
    if(!isToggled) {
      return false;
    } else if (isToggledEcho || isToggledBase) {
      return true;
    } else {
      return false;

    }
  }

  const handleOnChangeEcho = () => {
    setToggledEcho(!isToggledEcho)
  }

  const handleOnChangeBase = () => {
    setToggledBase(!isToggledBase)
  }
  
  const showMessage = (e) => {
    setShowInfo(e)
  }

  return (
    <>
      <LabeledWidget
        labelId={"form.label.offline_notifications"}
        name="enabled"
        labelClasses="mr-12"
        disabled={disabled}
        className="flex-1 ml-auto"
        widget={
          <ToggleSwitch id="offline_notifications_enabled" checked={isToggled} onChange={toggle} />
        }
        immReg
      />
      <OfflineNotificationEditors
        name="triggers"
        hidden={isToggled}
        base={handleOnChangeBase}
        echo={handleOnChangeEcho}
        showMessage={(e) => showMessage(e)}
      />
      <ActionsEditor name="emails" className={!shouldBeVisible ? "hidden" : ""} disabled={disabled} visible={shouldBeVisible()} />
      <label style={{"textAlign": "end"}} className={`col-span-full text-slate-600 text-sm justify-end mt-10 text-end ${showInfo ? "" : "hidden"}`}><FormattedMessage id="conditions.legend.low_value_notification"></FormattedMessage></label>
    </>
  );
};

export const SiteSelect = (props) => {
  return (
    <SiteContext.Consumer>
      {({ sites }) => {
        return (
          <LabeledWidget
            controlled
            isMulti
            labelId={"form.label.sites"}
            widget={<Select />}
            options={sites
              .sort((a, b) => a.name > b.name)
              .map(({ id: value, name: label }) => ({
                value,
                label,
              }))}
            onChange={(arr) => (arr ? arr.map((entry) => entry?.value) : [])}
            {...props}
          />
        );
      }}
    </SiteContext.Consumer>
  );
};

export const LocationFieldset = ({
  latitudeName = "latitude",
  longitudeName = "longitude",
  ...rest
}) => {
  return (
    <Fieldset
      legendId="form.legend.location"
      helpId="form.help.latlon"
      inline
      {...rest}
    >
      <LabeledWidget
        name={latitudeName}
        labelId="form.label.latitude"
        placeholder="Latitude"
        widget={<Input />}
      />
      <LabeledWidget
        name={longitudeName}
        labelId="form.label.longitude"
        placeholder="Longitude"
        widget={<Input />}
      />
    </Fieldset>
  );
};

export const BackInTimeSelect = (props) => (
  <Select
    white
    options={[
      { value: 1, label: "Last day" },
      { value: 7, label: "Last week" },
      { value: 30, label: "Last month" },
      { value: 90, label: "Last 3 months" },
      { value: 180, label: "Last 6 months" },
      { value: 365, label: "Last year" },
    ]}
    {...props}
    onChange={(obj) => props?.onChange(obj.value)}
  />
);

export const ActionsEditor = ({ name, disabled, visible }) => {
  const { currentUser } = useContext(UserContext);
  const { errors } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    name,
  });


  return (
    <>
      {visible === true && (
        <div>
          <p className="font-bold sm:block">
            <FormattedMessage id="form.legend.actions" />
          </p>
          {errors.emails && (
            <ValidationMessage messageId="validation.required" />
          )}
        </div>
      )}
      {fields.map((field, idx) => (
        <div
          key={field.id}
          className="col-span-2 sm:col-span-1 sm:col-start-2 flex w-full"
        >
          <LabeledWidget
            name={`${name}[${idx}].email`}
            className={visible ? "flex-1" : "hidden"}
            defaultValue={name === "emails" ? field.value : field.email}
            placeholder="user@example.com"
            widget={<Input />}
            disabled={disabled}
            immReg
          />
          {!disabled && (
            <button
              type="button"
              className={visible ? "px-2 btn" : "hidden"}
              onClick={(evt) => {
                evt.preventDefault();
                remove(idx);
              }}
            >
              <FiX className="text-scout-red-light" size={24} />
            </button>
          )}
        </div>
      ))}
      {!disabled && visible && (
        <div
          className={
            visible
              ? "w-full col-span-2 sm:col-span-1 sm:col-start-2 pr-20"
              : "hidden"
          }
        >
          <div className="w-full flex justify-center">
            <button
              type="button"
              className={visible ? "px-2 btn" : "hidden"}
              onClick={(evt) => {
                evt.preventDefault();
                if (fields.some(({ email }) => email === currentUser.email)) {
                  append();
                } else {
                  append({
                    email: currentUser.email,
                  });
                }
              }}
            >
              <FiPlus className="text-scout-green-light" size={24} />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export const OfflineNotificationEditors = ({
  name,
  base,
  echo,
  hidden,
  showMessage
}) => {
  const { fields } = useFieldArray({
    name,
  });
  const [openFields, setOpenFields] = useState(fields)
  const [triggerBase, setTriggerBase] = useState()
  const [triggerEcho, setTriggerEcho] = useState()
  const { register } = useFormContext();
  const intl = useIntl();

  const handleOnChange = (e) => {
    if(openFields.length > 0) {
      openFields.map((field, idx) => {
        if(e.target.name === field.device_type) {
          openFields.splice(idx, 1)
          setOpenFields(openFields)
          console.log(fields)
          if(e.target.name === "base"){
            base()
          }
          if(e.target.name === "echo"){
            echo()
          }
        } else {
          setOpenFields([...openFields, {device_type: e.target.name}])
          console.log(fields)
          if(e.target.name === "base"){
            base()
          }
          if(e.target.name === "echo"){
            echo()
          }
        }
        return true;
      });

    } else {
      setOpenFields([...openFields, {device_type: e.target.name}])
      if(e.target.name === "base"){
        base()
      }
      if(e.target.name === "echo"){
        echo()
      }
    }
  }

  const setValueBase = (e) => {
    setTriggerBase(e.target.value)
  }
  const setValueEcho = (e) => {
    setTriggerEcho(e.target.value)
  }

  if(triggerEcho < 6 || triggerBase < 6){
    showMessage(true)
  } else {
    showMessage(false)
  }

  return hidden ?  (
    <>
      <LabeledWidget
        labelId={"conditions.legend.base_description"}
        labelClasses="ml-8"
        name="base"
        className="flex-1 ml-auto flex items-center justify-end"
        widget={
          <>
            <input
              className="hidden"
              name={`${name}[${openFields.findIndex(e => e.device_type === "base")}].device_type`}
              defaultValue={openFields.find(e=> e.device_type === "base")?.device_type}
              ref={register()}
            />
            <label className={`justify-start mr-3 text-slate-600 ${openFields.findIndex(e => e.device_type === "base") >= 0? "" : "hidden"}`}>has been offline for [h]</label>
            <LabeledWidget
              className="flex-row mr-3"
              name={`${name}[${openFields.findIndex(e => e.device_type === "base")}].trigger_time`}
              defaultValue={openFields.find(e => e.device_type === "base")?.trigger_time / 60}
              widget={
                <Input
                  className="text-right w-auto"
                  style={{"width": "auto"}}
                  placeholder={intl.formatMessage({
                    id: "form.label.trigger_time",
                  })}
                  type="number"
                  onChange={setValueBase}
                  min={1}
                />
              }
              visible={openFields.findIndex(e => e.device_type === "base") >= 0}
              immReg
            />
            <ToggleSwitch name="base" id="base" checked={openFields.findIndex(e => e.device_type === "base") >= 0} onChange={handleOnChange} />
          </>
        }
      />
      <LabeledWidget
        labelId={"conditions.legend.echo_description"}
        name="echo"
        labelClasses="ml-8"
        className="flex-1 ml-auto flex items-center justify-end"
        widget={
          <>
            <input
              className="hidden"
              name={`${name}[${openFields.findIndex(e => e.device_type === "echo")}].device_type`}
              defaultValue={openFields.find(e=> e.device_type === "echo")?.device_type}
              ref={register()}
            />
            <label className={`justify-start mr-3 text-slate-600 col-start-2 ${openFields.findIndex(e => e.device_type === "echo") >= 0? "" : "hidden"}`}>has been offline for [h]</label>
          <LabeledWidget
            className="flex-row mr-3"
            name={`${name}[${openFields.findIndex(e => e.device_type === "echo")}].trigger_time`}
            defaultValue={openFields.find(e => e.device_type === "echo")?.trigger_time / 60}
            widget={
              <Input
                className="text-right"
                style={{"width": "auto"}}
                placeholder={intl.formatMessage({
                  id: "form.label.trigger_time",
                })}
                type="number"
                onChange={setValueEcho}
                min={1}
              />
            }
            visible={openFields.findIndex(e => e.device_type === "echo") >= 0}
            immReg
          />
        
          <ToggleSwitch name="echo" id="echo" checked={openFields.findIndex(e => e.device_type === "echo") >= 0} onChange={handleOnChange} />
          </>
        }
      />
    </>
  ) : (<></>);
}

export const OfflineNotificationConditionsEditor = ({
  name,
  required,
  disabled,
  hidden,
}) => {
  const intl = useIntl();
  const { errors, register } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    name,
  });
  const selectRef = useRef();
  const left = ["base", "echo"].filter(
    (name) => !fields.map((field) => field.device_type).includes(name)
  );
  const error = errors[name]?.message;
  const [missingConditionError, setMissingConditionError] = useState(undefined);

  function validateConditions() {
    let value = document.forms[0]?.selectconditions.value;
    value !== "SELECT" && setMissingConditionError(undefined);
  }

  return hidden ? (
    <>
      <FormLabel
        htmlFor={`input-${name}`}
        messageId="form.legend.conditions"
        disabled={disabled}
        required={required}
      />
      <div className="col-span-2 sm:col-span-1 sm:col-start-2 flex w-full">
        <div className="flex-grow">
          <PlainSelect
            id="selectconditions"
            error={error}
            ref={selectRef}
            onChange={validateConditions}
            disabled={left.length === 0 || disabled}
          >
            {left.map((name) => (
              <FormattedMessage
                key={name}
                id={`conditions.legend.${name.toLowerCase()}`}
              >
                {(message) => <option value={name}>{message}</option>}
              </FormattedMessage>
            ))}
          </PlainSelect>
          {error && <ValidationMessage messageId={error} />}
          {missingConditionError && (
            <ValidationMessage messageId={missingConditionError} />
          )}
        </div>
        {!disabled && (
          <button
            type="button"
            disabled={left.length === 0}
            className="px-2 btn"
            onClick={(evt) => {
              evt.preventDefault();
              selectRef.current.value !== "SELECT"
                ? append({
                    device_type: selectRef.current.value,
                  })
                : setMissingConditionError("validation.choose_type");
            }}
          >
            <FiPlus className="text-scout-green-light" size={24} />
          </button>
        )}
      </div>
      {fields.map((field, idx) => {
        const legendId = `conditions.legend.${field.device_type.toLowerCase()}_description`;
        return (
          <Fragment key={field.id}>
            <input
              className="hidden"
              name={`${name}[${idx}].device_type`}
              defaultValue={field.device_type}
              ref={register()}
            />
            <div className="col-span-2 ml-auto inline-flex text-center">
              <Fieldset legendId={legendId} inline>
                <div className="w-full flex items-center">
                  <div className="flex-1">
                    <p className="font-medium sm:hidden">
                      <FormattedMessage id={legendId} />
                    </p>
                    <LabeledWidget
                      className="w-4/5 ml-auto"
                      name={`${name}[${idx}].trigger_time`}
                      defaultValue={field.trigger_time / 60}
                      widget={
                        <Input
                          className="text-right"
                          placeholder={intl.formatMessage({
                            id: "form.label.trigger_time",
                          })}
                          type="number"
                          min={1}
                        />
                      }
                      visible={hidden}
                      disabled={disabled}
                      immReg
                    />
                  </div>
                  {!disabled && (
                    <button
                      type="button"
                      className="px-2 btn"
                      onClick={(evt) => {
                        evt.preventDefault();
                        remove(idx);
                      }}
                    >
                      <FiX className="text-scout-red-light" size={24} />
                    </button>
                  )}
                </div>
              </Fieldset>
            </div>
          </Fragment>
        );
      })}
    </>
  ) : (
    <></>
  );
};

export const ConditionsEditor = ({ name, required, disabled, hidden }) => {
  const { currentUser } = useContext(UserContext);
  const { errors, register } = useFormContext();
  const { scouts } = useContext(DeviceContext);
  const { fields, append, remove } = useFieldArray({
    name,
  });
  const selectRef = useRef();
  const left = 
    scoutsHaveDevice(scouts, "209") ?
    [
      "SELECT",
      "MOISTURE",
      "TEMPERATURE",
      "SALINITY",
      "WATER_BALANCE",
      "OXYGEN",
    ].filter(
      (name) => !fields.map((field) => field.measurement_type).includes(name)
    ) :
    [
      "SELECT",
      "MOISTURE",
      "TEMPERATURE",
      "SALINITY",
      "WATER_BALANCE",
    ].filter(
      (name) => !fields.map((field) => field.measurement_type).includes(name)
    );
  const error = errors[name]?.message;
  const [missingConditionError, setMissingConditionError] = useState(undefined);
  const intl = useIntl();

  function validateConditions() {
    let value = document.forms[0]?.selectconditions.value;
    value !== "SELECT" && setMissingConditionError(undefined);
  }

  return (
    <>
      <FormLabel
        htmlFor={`input-${name}`}
        messageId="form.legend.conditions"
        disabled={disabled}
        required={required}
      />
      <div className="col-span-2 sm:col-span-1 sm:col-start-2 flex w-full">
        <div className="flex-grow">
          <PlainSelect
            id="selectconditions"
            error={error}
            ref={selectRef}
            onChange={validateConditions}
            disabled={left.length === 0 || disabled}
          >
            {left.map((name) => (
              <FormattedMessage
                key={name}
                id={`conditions.legend.${name.toLowerCase()}`}
              >
                {(message) => <option value={name}>{message}</option>}
              </FormattedMessage>
            ))}
          </PlainSelect>
          {error && <ValidationMessage messageId={error} />}
          {missingConditionError && (
            <ValidationMessage messageId={missingConditionError} />
          )}
        </div>
        {!disabled && (
          <button
            type="button"
            disabled={left.length === 0}
            className="px-2 btn"
            onClick={(evt) => {
              evt.preventDefault();
              selectRef.current.value !== "SELECT"
                ? append({
                    measurement_type: selectRef.current.value,
                  })
                : setMissingConditionError("validation.choose_type");
            }}
          >
            <FiPlus className="text-scout-green-light" size={24} />
          </button>
        )}
      </div>
      {fields.map((field, idx) => {
        const legendId =
          field.measurement_type.toLowerCase() === "temperature"
            ? `conditions.legend.${field.measurement_type.toLowerCase()}_${
                currentUser.pref_unit_temp
              }`
            : `conditions.legend.${field.measurement_type.toLowerCase()}_unit`;
        return (
          <Fragment key={field.id}>
            <input
              className="hidden"
              name={`${name}[${idx}].measurement_type`}
              defaultValue={field.measurement_type}
              ref={register()}
            />
            <Fieldset legendId={legendId} inline>
              <div className="w-full flex items-center">
                <div className="flex-1">
                  <p className="font-medium sm:hidden">
                    <FormattedMessage id={legendId} />
                  </p>

                  <LabeledWidget
                    name={`${name}[${idx}].inside_limits`}
                    defaultValue={field.inside_limits}
                    widget={
                      <PlainSelect>
                        <option value="true">{intl.formatMessage({id: "conditions_editor.options.stay_inside",})}</option>
                        <option value="false">{intl.formatMessage({id: "conditions_editor.options.stay_outside",})}</option>
                      </PlainSelect>
                    }
                    disabled={disabled}
                    immReg
                  />

                  <div className="flex items-center justify-between">
                    <LabeledWidget
                      className="flex-1"
                      name={`${name}[${idx}].low_limit`}
                      defaultValue={field.low_limit}
                      placeholder="Minimum"
                      widget={<Input type="number" />}
                      disabled={disabled}
                      immReg
                    />
                    <FiMinus className="mx-2 h-4 w-4" />
                    <LabeledWidget
                      className="flex-1"
                      name={`${name}[${idx}].high_limit`}
                      defaultValue={field.high_limit}
                      placeholder="Maximum"
                      widget={<Input type="number" />}
                      disabled={disabled}
                      immReg
                    />
                  </div>
                </div>
                {!disabled && (
                  <button
                    type="button"
                    className="px-2 btn"
                    onClick={(evt) => {
                      evt.preventDefault();
                      remove(idx);
                    }}
                  >
                    <FiX className="text-scout-red-light" size={24} />
                  </button>
                )}
              </div>
            </Fieldset>
          </Fragment>
        );
      })}
    </>
  );
};

export const UnitsFieldset = ({ nameLength, nameTemp }) => {
  const intl = useIntl();
  
  return (
    <Fieldset legendId="form.legend.units" inline>
      <LabeledWidget
        name={nameLength}
        labelId="form.label.unit_length"
        widget={
          <PlainSelect>
            <option value="metric">{intl.formatMessage({id: "profile_form.unit.metric"})}</option>
            <option value="imperial">{intl.formatMessage({id: "profile_form.unit.imperial"})}</option>
          </PlainSelect>
        }
      />
      <LabeledWidget
        name={nameTemp}
        labelId="form.label.unit_temp"
        widget={
          <PlainSelect>
            <option value="celsius">Celsius</option>
            <option value="fahrenheit">Fahrenheit</option>
          </PlainSelect>
        }
      />
    </Fieldset>
  );
};

export const MapContainer = ({
  value,
  onChange,
  withAttribution = false,
  withImage = false,
  disabled,
}) => {
  const mapRef = useRef();
  const [map, setMap] = useState();
  const [view, setView] = useState();
  const [showWidget, setShowWidget] = useState();
  const { currentSite } = useContext(SiteContext);

  const mapCenter = useMemo(
    () =>
      value?.longitude && value?.latitude
        ? [value.longitude, value.latitude]
        : currentSite?.longitude && currentSite?.latitude
        ? [currentSite.longitude, currentSite.latitude]
        : undefined,
    [
      currentSite?.latitude,
      currentSite?.longitude,
      value?.latitude,
      value?.longitude,
    ]
  );

  useEffect(() => {
    if (view && mapCenter) {
      view.center = mapCenter;
    }
  }, [view, mapCenter]);

  useEffect(() => {
    if (value && map && value.latitude && value.longitude) {
      loadModules(["esri/Graphic"]).then(([Graphic]) => {
        const graphicsLayer = map.allLayers.find(
          (layer) => layer.title === "pointlayer"
        );
        const { latitude, longitude } = value;

        var simpleMarkerSymbol = {
          type: "simple-marker",
          color: [54, 93, 245], // orange
          outline: {
            color: [255, 255, 255], // white
            width: 1,
          },
        };

        var point = {
          type: "point",
          longitude,
          latitude,
        };

        var pointGraphic = new Graphic({
          geometry: point,
          symbol: simpleMarkerSymbol,
        });

        graphicsLayer.removeAll();
        graphicsLayer.add(pointGraphic);
      });
    }
  }, [value, map]);

  setDefaultOptions({
    css: true,
    dojoConfig: {
      locale: "en",
      parseOnLoad: true,
    },
  });

  useEffect(() => {
    if (showWidget) {
      loadModules([
        "esri/Map",
        "esri/views/MapView",
        "esri/widgets/Search",
        "esri/layers/GraphicsLayer",
        "esri/tasks/support/Query",
        "esri/tasks/QueryTask",
      ]).then(([Map, MapView, Search, GraphicsLayer, Query, QueryTask]) => {
        if (!view) {
          const map = new Map({
            basemap: "hybrid",
          });
          let view = new MapView({
            container: mapRef.current,
            map: map,
            ...(mapCenter ? { center: mapCenter } : {}),
            zoom: 17,
          });
          var graphicsLayer = new GraphicsLayer({ title: "pointlayer" });
          map.add(graphicsLayer);

          const searchWidget = new Search({
            view: view,
          });
          view.ui.add(searchWidget, {
            position: "top-right",
            index: 2,
          });

          view.when(() => {
            setView(view);
            setMap(map);

            view.on("click", ({ mapPoint: { latitude, longitude } }) => {
              const [lat, lon] = [latitude.toFixed(6), longitude.toFixed(6)];
              var queryTask = new QueryTask(
                "https://services.arcgis.com/P3ePLMYs2RVChkJx/ArcGIS/rest/services/World_Time_Zones/FeatureServer/0"
              );
              var query = new Query();
              query.geometry = view.center;
              query.outFields = ["*"];
              queryTask.execute(query).then((results) => {
                if (view) {
                  let location = {
                    latitude: lat,
                    longitude: lon,
                    time_zone: results.features[0]?.attributes?.ZONE,
                  };
                  if (withImage) {
                    const baseMap = map.allLayers.find(
                      (layer) => layer.title === "World Imagery"
                    );
                    view
                      .takeScreenshot({
                        format: "png",
                        layers: [baseMap],
                        height: 224,
                        width: 336,
                      })
                      .then((screenshot) => {
                        if (withAttribution) {
                          const image = new Image();
                          image.src = screenshot.dataUrl;
                          image.onload = () => {
                            // The MapView screenshot API does not capture attribution information for
                            // the image, instead we have to paint this on top of the image ourselves
                            // by creating a temporary canvas and composing both images on that.

                            let tempCanvas = document.createElement("CANVAS");

                            // Drawing screenshot image
                            tempCanvas.height = image.height;
                            tempCanvas.width = image.width;
                            document.body.appendChild(tempCanvas);
                            const ctx = tempCanvas.getContext("2d");
                            ctx.drawImage(image, 0, 0);

                            // Drawing attribution background
                            const attrRect = document.getElementsByClassName(
                              "esri-component esri-attribution esri-widget"
                            )[0];
                            const rectStyle = window.getComputedStyle(attrRect);
                            ctx.beginPath();
                            ctx.rect(
                              0,
                              image.height - attrRect.offsetHeight,
                              attrRect.offsetWidth,
                              attrRect.offsetHeight
                            );
                            ctx.fillStyle = rectStyle.backgroundColor;
                            ctx.fill();

                            // Drawing attribution text
                            const attrTextLeft = document.getElementsByClassName(
                              "esri-attribution__sources esri-interactive"
                            )[0];
                            const attrTextRight = document.getElementsByClassName(
                              "esri-attribution__powered-by"
                            )[0];
                            const textStyle = window.getComputedStyle(
                              attrTextLeft
                            );
                            ctx.font = textStyle.font;
                            ctx.fillStyle = textStyle.color;
                            ctx.fillText(
                              attrTextLeft.textContent,
                              0,
                              image.height - 3
                            );
                            ctx.fillText(
                              attrTextRight.textContent,
                              image.width - attrTextRight.offsetWidth,
                              image.height - 3
                            );

                            location["image"] = tempCanvas.toDataURL(
                              "image/png"
                            );
                            document.body.removeChild(tempCanvas);
                          };
                        } else {
                          location["image"] = screenshot.dataUrl;
                          onChange({
                            ...location,
                            id: Math.random(),
                          });
                        }
                      });
                  }
                  onChange(location);
                }
              });
            });
          });

          return () => {
            if (view) {
              view.container = null;
            }
          };
        }
      });
    }
  }, [view, showWidget, mapCenter, onChange, withAttribution, withImage]);

  const { errors } = useFormContext();
  const error =
    errors.location?.latitude ??
    errors.location?.longitude ??
    errors.latlon?.latitude ??
    errors.latlon?.longitude;

  return (
    <>
      <div className="flex items-end col-gap-2 my-2" key={value?.id}>
        <label className="font-medium">
        <FormattedMessage id={"map.location.latitude"} />
          <Input
            defaultValue={value?.latitude}
            onChange={(event) =>
              onChange({
                longitude: value?.longitude,
                latitude: parseFloat(event.target.value).toFixed(6),
                id: value?.id,
                image: value?.image,
              })
            }
          />
        </label>
        <label className="ml-2 font-medium">
          <FormattedMessage id={"map.location.longitude"} />
          <Input
            defaultValue={value?.longitude}
            onChange={(event) =>
              onChange({
                longitude: parseFloat(event.target.value).toFixed(6),
                latitude: value?.latitude,
                id: value?.id,
                image: value?.image,
              })
            }
          />
        </label>
      </div>
      {error ? (
        <div className="mb-4 mx-auto">
          <ValidationMessage messageId={error.message} />
        </div>
      ) : (
        <></>
      )}
      {!disabled && (
        <>
          {showWidget ? (
            <div
              ref={mapRef}
              style={{
                height: "24rem",
              }}
              className=" w-full rounded border-scout-gray-input border overflow-hidden"
            />
          ) : (
            <div
              className="bg-scout-gray-input cursor-pointer w-full h-48 p-8 text-gray-500 flex flex-col items-center justify-center rounded"
              onClick={() => setShowWidget(true)}
            >
              <FiMapPin className="h-32 w-full" />
              <span className="mt-4 font-medium text-center">
                <FormattedMessage id={"map.enable"} />
                <div className="my-2" />
                <FormattedMessage id={"map.background_image"} />
              </span>
            </div>
          )}
        </>
      )}
    </>
  );
};

export const LocationPicker = (props) => {
  return (
    <LabeledWidget
      labelId="form.legend.location"
      widget={<MapContainer />}
      controlled
      {...props}
    />
  );
};
